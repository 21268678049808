import React from 'react';
import { css } from "@emotion/react";
import styled from '@emotion/styled';
import { Link } from "gatsby";
import ScriptTag from "react-script-tag";


//import Navegacion from './nav';

const EnlaceHome = styled(Link)`
    color: #fff;
    text-align: center;
    text-decoration: none;
`;

const Footer = ({title}) => {

    const year = new Date().getFullYear();

    return ( 
        <> 
        <footer
            css={css`
                background-color: rgba(44, 62, 80, .85);
                margin-top: 5rem;
                padding: 1rem;
            `}
        >
            <div
                css={css`
                    max-width: 1200px;
                    margin: auto;

                    @media (min-width: 768px){
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                `}
            >
                
                <EnlaceHome
                    to='/'
                >
                    <h1 css={css`
                        color:#fff;
                        font-weight: 700;
                        font-size: 3rem;
                    `}>ELECTRO
                    <span css={css`font-weight: 300`}
                    >NATURAL</span></h1>
                </EnlaceHome>            
            </div>
        </footer>
        <p
            css={css`
                text-align: center;
                color: #fff;
                background-color: rgb(33,44,55);
                margin: 0;
                pad: 1rem;
            `}
        >ElectroNatural Blog.  Todos los derechos reservados {year} &copy;</p>
        <ScriptTag
            id="hs-script-loader" async defer src="//js.hs-scripts.com/14547951.js"
        >
        </ScriptTag>
        </>
    );
}
 
export default Footer;