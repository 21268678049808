import React from 'react';
import { Link } from "gatsby";
import { css } from "@emotion/react";
import Headroom from 'react-headroom';

import Navegacion from './navegacion';

const Header = () => {

    return ( 
        <Headroom>
            <header
            css={css`
                background-color: #0D283B;
                padding: 1rem;
            `}
        >
            <div
                css={css`
                    max-width: 120rem;
                    margin: 0 auto;
                    text-align: center;

                    @media (min-width: 768px){
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                `}
            >
                <Link 
                    to='/'
                    css={css`
                        text-decoration:none;
                    `}
                >
                    <h1 css={css`
                        color:#fff;
                        font-weight: 700;
                        font-size: 4rem;
                    `}>ELECTRO
                    <span css={css`font-weight: 300`}
                    >NATURAL</span></h1>
                </Link>
                <Navegacion />
            </div>
        </header>
        </Headroom>
        
    );
}
 
export default Header;