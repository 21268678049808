import React from 'react';
import Helmet from 'react-helmet';
import { Global, css } from "@emotion/react";
import CookieConsent from "react-cookie-consent";

import Header from './header';
import Footer from './footer';
import Scroll from './scroll';

const Layout = ({children}) => {
    return (  
        <>
        <Global 
            styles={css`
                html{
                    font-size: 62.5%;
                    box-sizing: border-box;
                }
                *, *:before, *:after {
                    box-sizing: inherit;
                }
                body{
                    font-size: 1.6rem;
                    line-height: 2;
                    font-family: 'Lato', sans-serif;
                }
                h1,h2,h3{
                    margin: 0;
                    line-height: 1.5;
                }
                h1,h2{
                    text-align: center;
                    font-family: 'Lato', sans-serif;
                    font-weight: 300;
                }
                h3{
                    font-family: 'Roboto', sans-serif;
                }
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                .contenedor{
                    max-width: 120rem;
                    margin: 0 auto;
                    width: 95%;
                }
                img{
                    max-width: 100%;
                }
            `}
        />
        <Helmet>
            <title>ElectroNatural Blog</title>
            <meta name="description" content="Sitio web sobre energia" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" integrity="sha512-NhSC1YmyruXifcj/KFRWoC561YpHpc5Jtzgvbuzx5VozKpWvQ+4nXhPdFgmx8xqexRcpAglTj9sIBWINXa8x5w==" crossOrigin="anonymous" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
        </Helmet>
        <Header />
            {children}
            <Scroll showBelow={400} />
        <Footer />
        <CookieConsent
            location="bottom"
            buttonText="Acepto"
            enableDeclineButton
            onDecline={() => {
                alert("Entendemos gracias");
            }}
            declineButtonText="No Acepto"
            cookieName="gatsby-gdpr-google-analytics"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={29}
            sameSite="lax"
            >
            Este website utiliza cookies para mejorar la experiencia del usuario.{" "}
            <span style={{ fontSize: "10px" }}>electronatural.com :O</span>
        </CookieConsent>
        </>
    );
}
 
export default Layout;