import React from 'react';
import { Link } from "gatsby";
import styled from "@emotion/styled";

const Nav = styled.nav`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px){
        padding: 0;
        flex-direction: row;
    }
`;

const NavLink = styled(Link)`
    color: #ffffff;
    font-weight: 700;
    font-family: 'PT Sans', sans-serif;
    text-decoration: none;
    padding: .5rem;
    margin-right: 1rem;
    &:last-of-type{
        margin-right: 0;
    }
    &.pagina-actual{
        border-bottom: 2px solid #ffffff;
    }
`;



const Navegacion = () => {
    return (  
        <Nav>
            <NavLink
                to={'/'}
                activeClassName="pagina-actual"
            >Inicio</NavLink>
            <NavLink
                to={'/nosotros'}
                activeClassName="pagina-actual"
            >Nosotros</NavLink>
            <NavLink
                to={'/energia-ahorro'}
                activeClassName="pagina-actual"
            >Ahorro</NavLink>
            <NavLink
                to={'/energia-transformacion'}
                activeClassName="pagina-actual"
            >Transformación</NavLink>
            <NavLink
                to={'/energia-vital'}
                activeClassName="pagina-actual"
            >Energía Vital</NavLink>
            <NavLink
                to={'/publicaciones'}
                activeClassName="pagina-actual"
            >Publicaciones</NavLink>
            <NavLink
                to={'/proyectosAPP'}
                activeClassName="pagina-actual"
            >Proyectos</NavLink>
        </Nav>
    );
}
 
export default Navegacion;